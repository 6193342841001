import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
declare var swal: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  loginPageUrl = "/";
  emailid: any;
  password: any;
  fullname: any;
  desinations: any;
  collegeListData: any;
  collegename: any;
  college_Id: any;
  departmentListData: any;
  collegeDept_Id: any;
  errorMessage: string = '';

  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit() {
    this.getCampSiteListApi();
  }
  // loginpage(){
  //   this.router.navigate([this.loginPageUrl]);
  // }

  collegeNameId(items: any) {
    this.college_Id = items.id;
    this.departmentListApi();

  }





  async signupSubmit() {
    var postapi = "/sign_up_college";

    var signupdata = {
      "name": this.fullname,
      "email": this.emailid,
      // "mobile":"",
      // "dob":"",
      "password": this.password,
      "college_id": this.college_Id,
      "designation": this.desinations,
      "department_id": this.collegeDept_Id,
    };


    (await this.ApiService.postApi(postapi, signupdata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal.fire({
              title: 'Success!',
              text: 'You have successfully signed up!',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then(() => {
              this.router.navigate([this.loginPageUrl]);
            });
          }
          else if (res.status === 204 && res.message === "Already registerd,Waiting for approval") {
            swal.fire({
              title: 'Already Registered',
              text: 'This email is already registered and awaiting approval.',
              icon: 'info',
              confirmButtonText: 'OK'
            });
          } else {
            swal.fire({
              title: 'Error',
              text: 'An error occurred during signup. Please try again.',
              icon: 'error',
              confirmButtonText: 'OK'
            });
          }
        }
      },
      err => {
        if (err.error && err.error.message === "Already registerd,Waiting for approval") {
          swal.fire({
            title: 'Already Registered',
            text: 'This email is already registered and awaiting approval.',
            icon: 'info',
            confirmButtonText: 'OK'
          });
        } else {
          swal.fire({
            title: 'Error',
            text: 'An error occurred during signup. Please try again.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      }
    );
  }


  async departmentListApi() {
    var postapi = "/department_list";

    var signupdata = {
      "college_id": this.college_Id
    };

    (await this.ApiService.postApi(postapi, signupdata)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {

            this.departmentListData = res.msg;

            for (let item of this.departmentListData) {

            }

          }

        }
      });
  }




  signupAlready() {
    this.router.navigate([this.loginPageUrl]);
  }



  // ---------------------------------

  async getCampSiteListApi() {

    var getapi = "/college_list";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res) {
        if (res.status === 200) {

          this.collegeListData = res.data;



        }

      }




    });

  }
  collegDepartmentId(e: any) { 
    this.collegeDept_Id = e.target.value;;
    console.log(this.collegeDept_Id, 'yfgfhh');
}






}
