import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-studentportal-edit',
  templateUrl: './studentportal-edit.component.html',
  styleUrls: ['./studentportal-edit.component.css']
})
export class StudentportalEditComponent implements OnInit {
  isShown: boolean = false;
  editSubmit_Url = "/studentList";
  tokens:any;
  name:any;
  registerName:any;
  departments:any;
  workoutCompleted:any;
  noOfExpedition:any;
  totalPoints:any;
  EditId:any;
  collegeEdit_id:any;
  collegeDept_Id:any;
  collegeBatchYear:any;
  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit() {
    this.EditId=this.ApiService.student_Id ;
    this.collegeEdit_id=this.ApiService.collegeEditId;
    this.collegeDept_Id = this.ApiService.collegeDeptId;
    this.collegeBatchYear = this.ApiService.collegeBatchyear
    this.getstudentListApi();
  }

  async editSubmit(){
     {
      var postApi = "/edit_student";
      var deleteId = {
      //   "thusr_id":  this.EditId ,
      // "register_number": this.registerName,
      //  "department": this.departments ,
      //   "year": '0000',
// -------------------------------------

"thusr_id": this.EditId,

"register_number": this.registerName,

"department": this.departments,

"department_id": 5,

"college_id": 2,

"year": 2022

};
      (await this.ApiService.postApi(postApi, deleteId)).subscribe(
        res => {
          console.log(res);
          this.router.navigate([this.editSubmit_Url]);

        });
    }
  }

  async getstudentListApi() {
    this.tokens = localStorage.getItem('token');
    var getapi = "/student_list";

    var studentListData = {
    
      "college_id": this.collegeEdit_id,
  
      "department_id": this.collegeDept_Id,
  
      "year": this.collegeBatchYear,
      };


    (await this.ApiService.postApi(getapi, studentListData)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            var raja = res.data;
        
           for(let item of raja ){
               var rajalu = item.thusr_id;
              
               if( rajalu ==this.EditId ){
                this.name = item.thusr_name;
                this.registerName = item.register_number;
                this.departments = item.department;
                this.workoutCompleted  = item.workout_completed;
                this.noOfExpedition = item.exp_completed;
                this.totalPoints = item.thusr_happiness_points;

             
               }

              // var pandi = item.thusr_name;  register_number
              // console.log(pandi,'pandiii');
            }
          }

        }
      });
  }

cancelBtn(){
  this.router.navigate([this.editSubmit_Url]);
}

}
