import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class ApiService {
    //  base:any= "http://10.1.5.14:4500";
    //  base:any= "https://api.thap.in";
    base:any= "https://stagingapi.thap.in";

    //  http://10.1.5.14/
    // 10.1.5.14

    //  https://fatneedle.com/credo_admin/admin_api

    // base:any= " https://fatneedle.com/credo_admin/admin_api/api";
  apiURL: string = this.base;
 // apiURL: string = "http://127.0.0.1:8000/api";
 student_Id:any;
 collegeEditId:any;
 collegeDeptId:any;
 collegeBatchyear:any;
  httpOptions = {

    
    headers: new HttpHeaders({
            "Access-Control-Allow-Origin" : '*',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization',
            // 'Authorization': 'Bearer ' +  (localStorage.getItem('tokens'))
            // 'Authorization':'Bearer' + (localStorage.getItem('tokens'))
          
      }),


  
  };

  
  // setHeaders:{

    // }

 /* httpOptions = {
    headers: new HttpHeaders({
      "content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      "Access-Control-Allow-Origin" : '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization'

    })
  };*/
 
  constructor(private httpClient: HttpClient,private router: Router) {}
  
  async postApi(Api_url, data: any): Promise<Observable<any>> {
    
    return this.httpClient.post(this.apiURL + Api_url, data);
 
  }
  async getApi(Api_url): Promise<Observable<any>> {

    
    
    return this.httpClient.get(this.apiURL + Api_url);
  }
  async deleteApi(Api_url): Promise<Observable<any>> {
    
    return this.httpClient.delete(this.apiURL + Api_url);
  }
  async putApi(Api_url, data: any): Promise<Observable<any>> {
    
    return this.httpClient.put(this.apiURL + Api_url, data);
}


  getToken(){
    // console.log(localStorage.getItem('token'));
    return localStorage.getItem('token')
  }
  
  /*getToken(){
    return localStorage.getItem('token')
  }
 loggedIn(){
   return !!localStorage.getItem('token')
 }
 logoutUser(){
localStorage.removeItem('token')
this.router.navigate(['/login'])
 }
 Restrictmodule(){
  console.log(!!localStorage.getItem('hide'))
  return !!localStorage.getItem('hide')
}*/
}
