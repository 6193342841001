import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import * as moment from 'moment';


declare var $: any;


// declare var moment :any;
@Component({
  selector: 'app-student-table',
  templateUrl: './student-table.component.html',
  styleUrls: ['./student-table.component.css']
})
export class StudentTableComponent implements OnInit {
  tokens: any;
  studentgetList: any;
  studentDltId: any;
  studentEdit_Url = "/studentEdit";
  studenthome_Url = "/";
  username: any;
  studentDept: any;
  college_Id: any;
  departmentlistdata: any;
  departmentlistdata_id: any;
  departments_Id: any;
  departDuration: any;
  batchYear: any;
  batchyears: any;
  deptYearSecond: any;
  fileName: any;
  data: any;
  downloadtemplate: any;
  dept: any;
  year: any;
  constructor(private router: Router, private ApiService: ApiService) { }


  ngOnInit() {



    this.tokens = localStorage.getItem('token');
    this.username = localStorage.getItem('userName');
    this.college_Id = localStorage.getItem('collegeid');

    // this.getstudentListApi();


    this.departmentList();
    // ---------------------------
    var duration = 4;

    this.batchyears = Array.from(new Array(duration)).map((u, i) => `${moment().subtract(i + 0.5, 'year').format('YYYY')} - ${moment().add(duration - (i + 0.5), 'year').format('YYYY')}`)
    console.log(this.batchyears, 'raja', this.batchyears.map(o => Number(o.split(" - ")[1])))
    // ---------------------------------

    // var tableToExcel = (function() {
    //   var uri = 'data:application/vnd.ms-excel;base64,'
    //     , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
    //     , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
    //     , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
    //   return function(table, name) {
    //     if (!table.nodeType) table = document.getElementById(table)
    //     var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}
    //     window.location.href = uri + base64(format(template, ctx))
    //   }
    // })()

    // document.getElementById("btn").addEventListener("click", () => {

    //   let table2excel = new Table2Excel();
    //   table2excel.export(document.querySelector("#Record"));
    // });




  }


  tableToCSV() {
    console.log('raa');
    // myTable

    // Variable to store the final csv data
    var csv_data = [];

    // Get each row data

    document.getElementById('myTable');
    var d = document.getElementById('myTable');
    var rows = d.getElementsByTagName('tr');

    for (var i = 0; i < rows.length; i++) {

      // Get each column data
      var cols = rows[i].querySelectorAll('td,th');
      // Stores each csv row data
      var csvrow = [];
      for (var j = 0; j < cols.length; j++) {

        // Get the text data of each cell
        // of a row and push it to csvrow
        csvrow.push(cols[j].innerHTML);
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
    }

    // Combine each row data with new line character
    var csv_da: string = csv_data.join('\n');

    // Call this function to download csv file 
    this.downloadCSVFile(csv_da);

  }


  downloadCSVFile(csv_data) {
    var CSVFile: any;
    // Create CSV file object and feed
    // our csv_data into it
    CSVFile = new Blob([csv_data], {
      type: "text/csv"
    });
    console.log(CSVFile, 'cdsfile')
    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement('a');

    // Download csv file
    temp_link.download = "Student List";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }



  studentDelete(deleteItem: any) {
    this.studentDltId = deleteItem.thusr_id;
  }
  async studentDeleteApi() {
    var postApi = "/remove_student";
    var deleteId = {
      "id": this.studentDltId
    };
    (await this.ApiService.postApi(postApi, deleteId)).subscribe(
      res => {
        console.log(res);
      });
  }

  studentEdit(items: any) {
    this.ApiService.student_Id = items.thusr_id;
    this.ApiService.collegeEditId = items.college_id;
    this.ApiService.collegeDeptId = items.department_id;
    this.ApiService.collegeBatchyear = items.year;
    this.router.navigate([this.studentEdit_Url]);
  }
  studentDeptIds(item: any) {
  }
  // ------------------------

  async departmentList() {
    var postApi = "/department_list";
    var deleteId = {
      "college_id": this.college_Id
    };
    (await this.ApiService.postApi(postApi, deleteId)).subscribe(
      res => {
        console.log(res.data);
        this.departmentlistdata = res.msg
        this.departmentlistdata_id = res.id
        localStorage.setItem("dept", res.data.id);
        console.log('hhhhh', res.data.id)
        console.log('eeeeee', this.departmentlistdata_id)
      });
  }



  async getstudentListApi() {
    var getapi = "/student_list";

    const dept = localStorage.getItem('dept')

    var studentListData = {

      "college_id": this.college_Id,

      "department_id": this.departments_Id,
      
      "year": this.year ,

    };

    console.log('AAAAAAAAA', this.departments_Id);
    console.log('BBBBBBBBB', this.batchyears);

    (await this.ApiService.postApi(getapi, studentListData)).subscribe(
      res => {
        console.log('Response:', res);
        if (res) {
          if (res.status === 200) {
            this.studentgetList = res.data;
          }
        }
      });
    error => {
      console.error('Error:', error);
    }
  }



  onChange(e: any) {
    var dept_Id = e.target.value;
    var myarray = dept_Id.split(',');

    for (var i = 0; i < myarray.length; i++) {
      this.departments_Id = myarray[0];
      this.departDuration = myarray[1];
    }

    // const dept = this.departments_Id;
    // const year = this.departDuration;

    console.log(this.departments_Id, 'rajj');
    console.log(this.departDuration, 'pandd');
  }


  deptChange(e: any) {
    var dept_Id = e.target.value;
    console.log(dept_Id, 'year1111111111');
    

    this.year = dept_Id;

    var myarray = dept_Id.split('-');


    for (var i = 0; i < myarray.length; i++) {
      var deptYearFirst = myarray[0];
      this.deptYearSecond = myarray[1];


      console.log(this.deptYearSecond, 'deptYearSecond');

    }
  }

  logoutBtn() {
    this.router.navigate([this.studenthome_Url]);
  }



}


