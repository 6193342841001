import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
declare var swal: any;
@Component({
  selector: 'app-update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.css']
})
export class UpdateEmailComponent implements OnInit {
  password:any;
  confirmPassword:any;
  emailinput:any;
  backtoSign_Url ="/";
  backtoUp_Url = "/signup";
  constructor(private ApiService: ApiService,private router: Router ) { }

  ngOnInit(): void {

  }

 async updateEmails(){
    var postapi = "/forgot_password_college";

    var signupdata = {
      'email':this.emailinput,
    };

    (await this.ApiService.postApi(postapi, signupdata)).subscribe(
      res => {
        if (res) {

          if (res.status === 200) {
            var msgText = res.message
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: msgText,
            })

          }
          else if (res.status == 404) {
            var msgText1 = res.message
            swal.fire({
              icon: 'error',
              title: 'error',
              text: msgText1,
            })

          }
        }

      });
  }

  backtoSign(){
    this.router.navigate([this.backtoSign_Url]);
  }
  backtoSignUp(){
    this.router.navigate([this.backtoUp_Url]);
  }

}
