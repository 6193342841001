import { Injectable,Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})

@Injectable()
export class TokenInterceptorInterceptor implements HttpInterceptor {

  constructor(private injector:Injector,private ApiService: ApiService) { }
  intercept(req, next) {

    if (this.isValidRequestForInterceptor(req.url)) {
     
          let apiService=this.injector.get(ApiService)
          let tokenizedReq = req.clone({
            setHeaders:{
              Authorization:`'Bearer' ${apiService.getToken()}`
            }
          })
          return next.handle(tokenizedReq);
    }
    return next.handle(req);

    
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    //console.log(requestUrl);
    let positionIndicator: string = 'ngx-agora-sdk-ng.herokuapp.com';
    let position = requestUrl.indexOf(positionIndicator);
    //console.log('pos',position);
    if (position > 0) {
      return false;
    }
    return true;
  }
 
}
