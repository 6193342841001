
<div>

    <div class="header " >
        <div class="row">

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <a href="#default" class="logo"> <img style="height: 115px; width: 150px; " src="assets/Web_logo.png" alt=""> </a>
               </div>
               <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                   <div style="display: flex;align-items: center;height: 100%;">
                    <p class="header_heading clr_white fsize30">An adventure to wholesome life awaits..</p>
                   </div>
    
               </div>
              
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                <div class=" " style="display: flex;align-items: center;height: 100%;">
                    <!-- <a class="active" href="#home">Home</a>
                    <a href="#contact">Contact</a> -->
                    <!-- <a  class="clr_white fsize30 " >About</a> -->
                  </div>
            </div>
     

        </div>

      </div>
      
      <div >
      

 <!-- <div class="bodyBgrClr" style="margin-top: 1rem;" >
 <div >
  <div class="row">


    
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">

     

        </div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">

            <div style="display: flex;align-items: center;height:100vh">
           
              <div class="cardDiv">
                <div style="display: flex;justify-content: center;">
                    <img src="../assets/happy_img.png" alt="" style="width: 250px;margin: -114px auto;">
                                    </div>
 
                <div>
                    Login
                </div>

              </div>
             </div>


        </div>


        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">

          


        </div>


    </div>
       </div>
    </div> -->

    <div class="row"  style="margin:0px;">
        <div class="body_bar col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 circle-section">
          
          <div class="container pb-5  ">
            
            <div class="row justify-content-center" style="align-items: center; height: 100vh;" >
              
              <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">  
                <div class="card cardSize shadow border-0 ">
                  <!-- <img class="img-fluid" src="assets/img/Characters/happy.png"> -->
                  <div class="card-body ">
                    <div class="text-center text-muted mb-3 mt-4">
                      <small class="login">College Login </small>
                    </div>
                    <div class="text-center text-muted mb-4">
                      <small class="login_text">Happiness College Portal</small>
                    </div>
                    <form role="form">

                    <div style="padding: 10px 40px;" >

                        <div class="form-group mb-3">
                            <div class=" ">
                              <input class="form-control emailInput" placeholder=" Email id" id="emailid" type="email"
                              [(ngModel)]="emails" [ngModelOptions]="{standalone: true}"
                              maxlength="150"
                              >
                            </div>
                          </div>
          
                          <div class="form-group mb-3">
                            <div class=" ">
                              <input class="form-control emailInput" placeholder="Password" id="password" type="password"
                              maxlength="150"
                              [(ngModel)]="password" [ngModelOptions]="{standalone: true}"
                              >
                            </div>
                          </div>
                  
                          
                          <div class=" marg-t-18">
                            <button type="button" (click)="submit_btn()"    class="btn submit_button">Submit</button>
                          </div>

                          <div class=" marg-t-18 ">
                              <span style="color: #6CC3B8;" (click)="forgotPage()"  >
                                forgot  password
                              </span>

                              <span (click)="signupPage()" style="margin-right: 10px;float: right;color: #6CC3B8;" >Signup</span>
                          </div>


                    </div>




           
        
                      <!-- <div class="row mt-3">
                        <div class="col-6">
           <a href="" class=" blue" >Forgot password?</a>
                              </div>
                        <div class="col-6 text-right">
                          <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/signup']" class=" blue">
                             Signup
                           </a>
                        </div>
                      </div> -->
                      
                    </form>
                  </div>
                </div>
               
              </div>
               </div>
          
        </div>
      
        </div>
      </div>
 



  
      </div>

</div>