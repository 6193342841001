import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  password:any;
  confirmPassword:any;
  signupPageUrl = "/signup";
  loginPageUrl = "/";
  // ----------------------------

  isShown: boolean = false;
  forgetEmail: any;
  forgetpasswordKey: any;

  submitted: boolean = false;

  // ------------------------
  constructor(private activatedRoute: ActivatedRoute,private router: Router , private ApiService: ApiService) { }

  ngOnInit() {

    this.forgetEmail = JSON.parse(localStorage.getItem('currentUser'));
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params.email, 'paramsEmail');
      this.forgetEmail = params.email;
      this.forgetpasswordKey = params.key;
      console.log(params.key, 'paramsKey');
      const userId = params['userId'];
      console.log(userId);
    });



  }
  signupPage(){
    this.router.navigate([this.signupPageUrl]);
  }
  
  loginpage(){
    this.router.navigate([this.loginPageUrl]);
  }

  async onSubmit() {
    this.submitted = true;
    if( this.confirmPassword !=''){
    var postapi = "/update_password_faculty";
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    console.log(currentUser, 'currentusers'); 
    var password = $("#newpassword").val();
    console.log(password, 'newpass');

    var signupdata = {
      "email": this.forgetEmail,
      "password": password,
      "key": this.forgetpasswordKey

    };
    (await this.ApiService.postApi(postapi, signupdata)).subscribe(
      res => {

        if (res) {

          if (res.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Password Updated Successfully',
            })

          }
          else if (res.status == 404) {
            swal.fire({
              icon: 'error',
              title: 'error',
              text: 'Key does not match',
            })

          }
        }

      });
    }


  }



}
