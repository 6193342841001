import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { UpdateEmailComponent } from './update-email/update-email.component';
import { StudentTableComponent } from './student-table/student-table.component';
import { StudentportalEditComponent } from './studentportal-edit/studentportal-edit.component';


const routes: Routes = [
  {
    path: '',
    component: HeaderComponent,
    // pathMatch: 'full',
  },
  {
    path: 'signup',
    component: SignupComponent,
    // pathMatch: 'full',
  },
  {
    path: 'forgotmailpassword',
    component: ForgotpasswordComponent,
    // pathMatch: 'full',
  },
  {
    path: 'updateEmail',
    component: UpdateEmailComponent,
    // pathMatch: 'full',
  },

  {
    path: 'studentList',
    component: StudentTableComponent,
  
  },

  
  {
    path: 'studentEdit',
    component: StudentportalEditComponent,
  
  },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true} )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
