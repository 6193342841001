<div>
    <div class="header ">
        <div class="row">

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <a href="#default" class="logo"> <img style="height: 115px; width: 150px; " src="assets/Web_logo.png"
                        alt=""> </a>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div style="display: flex;align-items: center;height: 100%;">
                    <p class="header_heading clr_white fsize30">An adventure to wholesome life awaits..</p>
                </div>

            </div>

            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <div class=" " style="display: flex;align-items: center;height: 100%;">

                    <a class="clr_white fsize30 ">About</a>
                </div>
            </div>


        </div>

    </div>

    <!-- ---------------------------------------------------------------- -->

    <div class="row">
        <div class="body_bar col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 ">

            <div class="container  pb-5 margin_t_60 ">

                <div class="row justify-content-center">

                    <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                        <div class="card cardSize shadow border-0">

                            <div class="">
                                <div class="text-center text-muted mb-3 mt-4">
                                    <small class="login">Forgot Password</small>
                                </div>
                                <div class="text-center text-muted mb-4">
                                    <small class="login_text">Happiness College Portal</small>
                                </div>
                                <form>
                                    <div style="padding: 10px 40px;">


                                        <div class="form-group mb-3">
                                            <div class=" " style="display: flex; justify-content: center; ">
                                                <input class=" emailInput" style="padding-left: 15px;"
                                                    placeholder="Please Enter the Email" [(ngModel)]="emailinput"
                                                    [ngModelOptions]="{standalone: true}" type="text"
                                                    autocomplete="off">
                                            </div>

                                        </div>



                                        <div class=" marg-t-18 " style="display: flex; justify-content: center; ">
                                            <button type="submit" class="btn submit_button" data-toggle="modal"
                                                (click)="updateEmails()">Submit</button>
                                        </div>


                                        <div class=" mt-3" style="width: 270px;">
                                            <div>
                                                <span class=" blue" style="margin-left: 20px;" (click)="backtoSign()" >
                                                    Back to Signin
                                                </span>
                                                <span class=" blue" style="float: right;" (click)="backtoSignUp()"  >
                                                    Signup
                                                </span>

                                            </div>
                                        </div>


                                    </div>



                                </form>
                            </div>
                        </div>

                    </div>


                </div>


            </div>

        </div>
    </div>



</div>