<div class="row header_bar">

  <div class="col-2 project_name align_center ">
    <img alt="Image placeholder" class="img-fluid log_img" src="assets/Web_logo.png">
  </div>
  <div class="col-8 align_center header_heading">
    <p class="header_heading">An adventure to wholesome life awaits..</p>
  </div>
  <div calss="col-2 pt_5">

  </div>
</div>




<div class="row">
  <div class="body_bar col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 circle-section">


    <div class="container  pb-5 ">

      <div class="row justify-content-center">

        <div class="col-lg-10 col-md-6 col-sm-6 col-xs-12">
          <div class="card shadow border-0">

            <div class="card-body px-lg-5 ">
              <div class="text-center text-muted mb-3 mt-4">
                <small class="login">Signup</small>
              </div>
              <div class="text-center text-muted mb-4">
                <small class="login_text">Happiness College Portal</small>
              </div>
              <div>

                <form>
                  <div class="form-group">

                    <div class="row marg-0">
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label class="fsize16 clr_black font_bold_gilroy">Email</label>
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <input type="text" id="email" class="form-control input_bdr_clr " maxlength="100"
                          [(ngModel)]="emailid" [ngModelOptions]="{standalone: true}" />

                      </div>
                    </div>

                  </div>

                  <div class="">

                    <div class="row marg-0">
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label class="fsize16 clr_black font_bold_gilroy">Password</label>
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div class="form-group">
                          <div>
                            <input type="password" id="passWord" [(ngModel)]="password"
                              [ngModelOptions]="{standalone: true}" maxlength="100"
                              class="form-control input_bdr_clr " />
                          </div>


                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="form-group">
                    <div class="row marg-0">
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label class="fsize16 clr_black font_bold_gilroy">Confirm Password</label>
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <input type="password" id="confirmPassword" maxlength="100"
                          class="form-control input_bdr_clr " />


                      </div>
                    </div>
                  </div>
                  <div class="form-group">

                    <div class="row marg-0">
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label class="fsize16 clr_black font_bold_gilroy">Full Name</label>
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <input type="text" id="fullName" maxlength="100" class="form-control input_bdr_clr "
                          [(ngModel)]="fullname" [ngModelOptions]="{standalone: true}" />

                      </div>

                    </div>

                  </div>


                  <div class="form-group">

                    <div class="row marg-0">
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label class="fsize16 clr_black font_bold_gilroy">College Name</label>
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <!-- <input type="text" id="areaOfexpertise" 
                            class="form-control input_bdr_clr" maxlength="100"  
                         
                            />-->

                        <select [(ngModel)]="collegename" [ngModelOptions]="{standalone: true}"
                          class=" form-control input_bdr_clr selectpicker myFakeClass"
                          (ngModelChange)="collegeNameId($event)">
                          <option [ngValue]="item" *ngFor="let item of collegeListData"> {{item.name}} </option>
                        </select>

                      </div>

                    </div>

                  </div>


                  <div class="form-group">

                    <div class="row marg-0">
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label class="fsize16 clr_black font_bold_gilroy">College Department</label>
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">


                        <select class=" form-control input_bdr_clr selectpicker myFakeClass"
                          (change)="collegDepartmentId($event)">
                          <option>Choose The College Department</option>
                          <option [value]="item.id" *ngFor="let item of departmentListData"> {{item.department}}
                          </option>
                        </select>

                      </div>

                    </div>

                  </div>



                  <div class="form-group">

                    <div class="row marg-0">
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label class="fsize16 clr_black font_bold_gilroy">Desination</label>
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <input type="text" id="accreditingBody" [(ngModel)]="desinations"
                          [ngModelOptions]="{standalone: true}" class="form-control input_bdr_clr " maxlength="100" />
                      </div>
                    </div>
                  </div>



                </form>


                <div class="row  marg-t-40 ">
                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                  </div>

                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                    <div class=" upload-form" style="margin-bottom: 32px;">
                      <button type="button" (click)="signupSubmit()" class="signup_btn">Submit</button>
                      <div *ngIf="errorMessage" class="bg-danger">{{ errorMessage }}</div>
                    </div>

                  </div>
                </div>


                <!-- <div>
                    <button >raja</button>
                  </div> -->

                <div class="row   ">
                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                  </div>

                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                    <div class="form-group marg-t-40">
                      <button (click)="signupAlready()" class="btn  already_Sign_btn">Already have account <span
                          class="clr_green">Signin</span> </button>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>



  </div>
</div>

<div class="basr_cls div_last"></div>