<div>

  <div class="header ">
    <div class="row">

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
        <a href="#default" class="logo"> <img style="height: 115px; width: 150px; " src="assets/Web_logo.png" alt="">
        </a>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <div style="display: flex;align-items: center;height: 100%;">
          <p class="header_heading clr_white fsize30">An adventure to wholesome life awaits..</p>
        </div>

      </div>

      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
        <div class=" " style="display: flex;align-items: center;height: 100%;">
          <!-- <span class="clr_white" style="font-size: 18px;">{{username}}</span> -->


          <div class="dropdown show" style="width: 140px;">
            <a style="width: 150px; background-color: #fff; " class="btn  dropdown-toggle wrapperText" href="#"
              role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{username}}
              <!-- Rajapandi -->
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a class="dropdown-item" style="font-size: 16px;padding: 2px;" (click)="logoutBtn()">Logout</a>

            </div>
          </div>

        </div>
      </div>


    </div>

  </div>

  <!-- -------------------------------------------------  -->
  <div style="padding: 20px 40px; color:#FF8C45; font-size: 30px; margin-top: 20px; ">

    College Portal Management

  </div>
  <!-- ---------------------------- -->

  <div style="display:flex; justify-content: center; margin-top:20px; ">
    <div>
      <!-- <input type="text" placeholder="please enter the dept" > -->
      <div>
        <label style="margin:0px;">Department</label>
        <div>
          <!-- <select style="height:40px; width:300px; border-radius: 8px;"   [(ngModel)]="studentDept" [ngModelOptions]="{standalone: true}"
(ngModelChange)="studentDeptIds($event)"
class="input_bdr_clr selectpicker myFakeClass">
<option selected disabled val="">Select Emotions type</option>
<option >
    DepartmentName</option>
</select> -->

          <select style="height:40px; width:300px; border-radius: 8px; padding:5px 8px ;" class="category_input"
            (change)="onChange($event)">
            <option disabled selected>Please choost Department</option>
            <option *ngFor="let cat of departmentlistdata" [value]="[cat.id,cat.duration]">

              {{cat.department}}

            </option>

          </select>

        </div>

      </div>

      <div style="margin-top:20px;">
        <label style="margin:4px;">Year Passed Out</label>
        <div>
          <!-- <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="batchYear"
  placeholder="Please Enter Year Passed Out"
  [ngModelOptions]="{standalone: true}"  /> -->

          <select style="height:40px; width:300px; border-radius: 8px; padding: 5px 8px;" class="category_input"
            (change)="deptChange($event)">
            <option disabled selected>Please choost Department</option>
            <option *ngFor="let item of batchyears" [value]="[item]">

              {{item}}

            </option>
          </select>
        </div>

      </div>

      <div style="margin-top:30px;">
        <button
          style="background-color:#6CC3B8;height:40px; width:300px; border-radius: 8px; border: 1px solid #6CC3B8 ; "
          class="" (click)="getstudentListApi()">Submit</button>
      </div>

    </div>

  </div>

  <!-- ------------------------------------- -->

  <div class="card table_card" style="margin:40px 30px 10px 30px ;">
    <table id="myTable" class="row-border hover">
      <thead>
        <tr style="color:#6CC3B8; font-size: 18px;border-bottom: 1px solid #e9ecef;">
          <th>S.No</th>

          <th>Student name</th>
          <th> Register Number</th>
          <th>Department</th>
          <th> No of workouts completed</th>
          <th>No of expeditions</th>
          <th>No of Session Booked</th>
          <th>Total points earned</th>

          <div style="color:#6CC3B8; font-size: 18px;padding:20px 15px; font-weight: 600;">Action</div>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of studentgetList;let i = index" style="border-bottom: 1px solid #e9ecef;">
          <td>{{i+1}}</td>
          <td>{{item.thusr_name}}</td>
          <td>{{item.register_number}}</td>
          <td>{{item.department}}</td>
          <td>{{item.workout_completed}}</td>
          <td>{{item.exp_completed}}</td>
          <td>{{item.appointments_count}}</td>
          <td>{{item.thusr_happiness_points}}</td>
          <div style="width: 120px; padding:20px 15px;">

            <span>
              <img class="h-20 w-20 marg-l-10" src="assets\Edit_icon.png" alt="" (click)="studentEdit(item)">

            </span>
            <!-- <img      data-toggle="modal"
            data-target="#exampleModal"  class="h-20 w-20 marg-l-10"  src="assets\Delete_icon.png" alt="" (click)="studentDelete(item)" > -->
          </div>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- ------------------DownLoad Option---------------------------------------- -->

  <div style="float:right;">
    <button type="button" (click)="tableToCSV()"
      style="background-color:#6CC3B8; height:40px; width:140px;color:#000;margin-right:40px;margin-top:20px;margin-bottom:20px; border-radius: 6px;">
      download CSV
    </button>
  </div>


  <!-- -------------------Model Pop up--------------------------------- -->

  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <div class="modal-body">

          <div class="fsize18 " style="font-weight: 500;"> Confirmation ! </div>
          <div class="font_regular_gilroy" style="margin-top: 10px;">
            Do you Want to Delete College ?
          </div>

        </div>
        <div class="padd-b-30 row justify-content-center  font_bold_gilroy">
          <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
            (click)="studentDeleteApi()">Submit</button>
          <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>




</div>