import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
declare var swal: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  email:any;
  password:any;
  signupPageUrl = "/signup";
  forgotPageUrl = "/updateEmail";
  emails:any;
  studentListPageUrl = "/studentList";
  errormsgs:any;

  constructor(private router: Router,private ApiService: ApiService) { }

  ngOnInit(): void {
  }

  signupPage(){
    this.router.navigate([this.signupPageUrl]);
  }

  forgotPage(){
    this.router.navigate([this.forgotPageUrl]);
  }

  async submit_btn(){
    var postapi = "/login_college";

    var signupdata = {
         'email': this.emails,
         'password': this.password
       };
  (await this.ApiService.postApi(postapi, signupdata)).subscribe(
      res => {

          if (res) {
              if (res.status === 200) {
                localStorage.setItem('token', res.token);
                localStorage.setItem('userName', res.data.thusr_name);
                localStorage.setItem('collegeid', res.data.college_id);
                localStorage.setItem('department_id',res.data.departments_Id);
                localStorage.setItem('year',res.data.deptYearSecond);
              
                
                this.router.navigate([this.studentListPageUrl]);
                   }

                   if (res.status === 401) {

                    this.errormsgs = res.message;
          swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: this.errormsgs,
                    })
           console.log(res.message)
            // swal({
                    //   icon: 'error',
                    //   title: 'Oops...',
                    //   text: "Invalid username and password",
                    //   type: "error"
                    // }, function () {
                    //   alert('yo');
                    // });
          
                  }
          
            }
     });
  }


}
