import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'studentthap';

  async ngOnInit() {


    var autologout;
    var count = 0;

    resettimer();
    $(document).on('mouseover mousedown touchstart click keydown mousewheel DDMouseScroll wheel scroll', document, function (e) {
      resettimer();
    });

    function resettimer() {
      count = 0
      clearInterval(autologout)
      autologout = setInterval(idleLogout, 2 * 60 * 60 * 1000)
    }

    function idleLogout() {

      var inactive = 2 * 60 * 60 * 1000;

      if (++count >= inactive) {
        localStorage.clear()
        count = 0
        window.location.href = '/#'
      }
    }

  }

}
