import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SignupComponent } from './signup/signup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { HttpClientModule , HTTP_INTERCEPTORS} from '@angular/common/http';
import { UpdateEmailComponent } from './update-email/update-email.component';
import { StudentTableComponent } from './student-table/student-table.component';
import { TokenInterceptorInterceptor } from './token-interceptor.interceptor';
import { StudentportalEditComponent } from './studentportal-edit/studentportal-edit.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SignupComponent,
    LoginComponent,
    ForgotpasswordComponent,
    UpdateEmailComponent,
    StudentTableComponent,
    StudentportalEditComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule ,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    { provide:HTTP_INTERCEPTORS,
      useClass:TokenInterceptorInterceptor,
      multi:true}

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
