<div class="header ">
    <div class="row">

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <a href="#default" class="logo"> <img style="height: 115px; width: 150px; " src="assets/Web_logo.png"
                    alt=""> </a>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <div style="display: flex;align-items: center;height: 100%;">
                <p class="header_heading clr_white fsize30">An adventure to wholesome life awaits..</p>
            </div>

        </div>

        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div class=" " style="display: flex;align-items: center;height: 100%;">

                <!-- <a  class="clr_white fsize30 " >About</a> -->
            </div>
        </div>


    </div>

</div>

<!-- ------------------------------------- -->

<div class="padd-0-25">
    <div class="marg-t-30 fsize27 font_bold_gilroy">
        <nav aria-label="breadcrumb ">
            <ol class="breadcrumb fsize27 padd_0 ">
                <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                        [routerLink]="['/studentList']">Back</a></li>
                <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">College Portal Edit</li>
            </ol>
        </nav>
    </div>

    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <!-- -------------------------------------------------------- -->

                <div>
                    <div class="form-group">
                        <label class="fsize16 clr_black fw_500">Student name</label>
                        <input type="text" maxlength="150" class="form-control input_bdr_clr" disabled
                            [(ngModel)]="name" [ngModelOptions]="{standalone: true}" />



                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black fw_500">Register Number</label>
                        <input type="text" maxlength="150" class="form-control input_bdr_clr" [(ngModel)]="registerName"
                            [ngModelOptions]="{standalone: true}" />


                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black fw_500"> Department</label>
                        <input type="text" maxlength="150" disabled class="form-control input_bdr_clr" [(ngModel)]="departments"
                            [ngModelOptions]="{standalone: true}" />
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black fw_500">No. of workouts completed</label>
                        <input type="text" maxlength="150" class="form-control input_bdr_clr" disabled
                            [(ngModel)]="workoutCompleted" [ngModelOptions]="{standalone: true}" />
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black fw_500">No. of expeditions</label>
                        <input type="text" maxlength="150" class="form-control input_bdr_clr" disabled
                            [(ngModel)]="noOfExpedition" [ngModelOptions]="{standalone: true}" />
                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black fw_500">Total points earned</label>
                        <input type="text" maxlength="150" class="form-control input_bdr_clr" disabled
                            [(ngModel)]="totalPoints" [ngModelOptions]="{standalone: true}" />
                    </div>


                    <div class="form-group marg-t-40">
                        <div class="row m-0">

                            <div>
                                <button type="submit" class="btn  submit_btn_clr" (click)="editSubmit()">Submit</button>
                            </div>
                            <div>

                                <button type="button" class=" marg-l-16 cancelBtn_clr fsize16" (click)="cancelBtn()" >Cancel</button>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            </div>

        </div>
    </div>

</div>